<template>
<v-app><v-main class="loginbg"><v-container class="fill-height" fluid><v-row align="center" justify="center"><v-col cols="12" md="8"><v-form v-model="valid" ref="form"><v-card elevation="13" :loading="ajaxProcess">
    <v-toolbar color="success" dark flat><v-toolbar-title>{{ $t('Registration') }}</v-toolbar-title></v-toolbar>
    <v-card-text>
    <v-row>
        <v-col cols="12" md="6"><v-text-field :label="$t('Member Num.')" prepend-icon="fa-user" type="text" autocomplete="new-user" required  :rules="rules.isRequired" v-model="noanggota"/></v-col>
        <v-col cols="12" md="6"><v-text-field :label="$t('Full name')" prepend-icon="fa-check-circle" type="text" required  :rules="rules.isRequired" v-model="fullname"/></v-col>    
    </v-row>
    <v-row>
        <v-col cols="12" md="6"><v-text-field label="Password" prepend-icon="fa-key" autocomplete="new-password" :rules="rules.isRequired"
                    ref="passwordText" v-model="pass" :append-icon="iconpassword" @click:append="passwordeye" :type="typepasswordnya" ></v-text-field></v-col>
        <v-col cols="12" md="6"><v-text-field label="Re-Password" prepend-icon="fa-key" autocomplete="new-password" :rules="rules.isRequired"
                    ref="passwordText" v-model="pass2" :append-icon="iconpassword2" @click:append="passwordeye2" :type="typepasswordnya2" ></v-text-field></v-col>    
    </v-row>
    <v-row>
        <v-col cols="12" md="6"><v-text-field prepend-icon="fa-birthday-cake" :label="$t('Birth address')" v-model="birthaddress" /></v-col>
        <v-col cols="12" md="6"><v-menu v-model="tgllahir.tglcheck" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="tgllahir.text"
                    :label="$t('Birth date')"
                    prepend-icon="mdi-calendar-text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker v-model="tgllahir.val" @input="tgllahir.tglcheck = false" :allowed-dates="allowedDates" />
            </v-menu></v-col>    
    </v-row>
    <v-row>
        <v-col cols="12" md="6"><v-select
            prepend-icon="fa-check-circle"
          v-model="identityOptionValue"
          :items="identityoptions"
          item-text="nama"
          item-value="id"
          :label="$t('Identity type')"
          auto-select-first="true"
         /></v-col>
        <v-col cols="12" md="6"><v-text-field prepend-icon="fa-check-circle" :label="$t('Identity number')" v-model="nomoridentitas" /></v-col>    
    </v-row>
    <v-row>
        <v-col cols="12" md="6"><v-textarea prepend-icon="fa-map-marker" rows="2" :label="$t('Address')" v-model="address" /></v-col>
        <v-col cols="12" md="6"><v-text-field label="HP" prepend-icon="fa-phone" type="text" v-model="hp"/></v-col>    
    </v-row>
    <v-row>
        <v-col cols="12" md="6"><v-select
            prepend-icon="fa-check-circle"
          v-model="pekerjaanOptionValue"
          :items="pekerjaanoptions"
          item-text="pekerjaan"
          item-value="id"
          :label="$t('Job type')"
          auto-select-first="true"
         /></v-col>
    </v-row>
    </v-card-text>
    <v-card-text v-show="errorMessage.length > 0">
        <v-alert type="error" outlined>{{ errorMessage }}</v-alert>
    </v-card-text>
    <v-card-actions class="justify-center">
        <v-btn elevation="2" largex-large block class="success" :loading="ajaxProcess" @click="doRegister">{{ $t('Register') }}</v-btn>
    </v-card-actions>
</v-card></v-form></v-col></v-row></v-container></v-main></v-app>
</template>
<script>
const sha1 = require('sha1');
var dateused = new Date();
dateused.setFullYear(dateused.getFullYear() - 6);
var isoDate = dateused.toISOString().substr(0, 10);
export default {
  data: vm => ({
    noanggota: '',fullname:'',hp:'',address:'',birthaddress:'',nomoridentitas:'',
    tgllahir: {val:isoDate,text:vm.$parent.formatDate(isoDate),tglcheck:false},allowedDates:false,
    identityoptions:[],identityOptionValue:0,
    pekerjaanoptions:[],pekerjaanOptionValue:0,
    pass:'',typepasswordnya:'password',iconpassword:'fa-eye-slash',
    pass2:'',typepasswordnya2:'password',iconpassword2:'fa-eye-slash',
    valid: false,ajaxProcess:true,errorMessage:'',
    rules: {isRequired: [
      v => !!v || vm.$t('Is Required')
    ]}
  }),
  methods:{
    movetopassword() {
        this.$refs.passwordText.focus();
    },
    passwordeye() {
		this.typepasswordnya = (this.typepasswordnya == 'password' ? 'text' : 'password');
		this.iconpassword = (this.iconpassword == 'fa-eye-slash' ? 'fa-eye' : 'fa-eye-slash');
	},
    passwordeye2() {
		this.typepasswordnya2 = (this.typepasswordnya2 == 'password' ? 'text' : 'password');
		this.iconpassword2= (this.iconpassword2 == 'fa-eye-slash' ? 'fa-eye' : 'fa-eye-slash');
	},
	getdatenow() {
        var timeNow     = new Date(); 
        var year    = timeNow.getFullYear();
        var month   = timeNow.getMonth()+1; 
        if (month.length < 2) month = '0'+month;
        var day     = timeNow.getDate();
        if (day.length < 2) day = '0'+day;
        var hour    = timeNow.getHours();
        if (hour.length < 2) hour = '0'+hour;
        var minute  = timeNow.getMinutes();
        if (minute.length < 2) minute = '0'+minute;
        var second  = timeNow.getSeconds();
        if (second.length < 2) second = '0'+second;
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' +  second;
	},
    doRegister() {
        var vue = this;
        vue.$refs.form.validate();
        if (vue.ajaxProcess || !vue.valid) {
            return;
        }
        if (vue.pass != vue.pass2) {
            vue.errorMessage = vue.$t('Password and Re-Password is not equal');
            return;
        }
        vue.ajaxProcess = true;
        vue.errorMessage = '';
        var parameters = {noAnggota: vue.noanggota, fullName: vue.fullname, pass:sha1(vue.pass)
        ,hp:vue.hp
        ,address:vue.address
        ,birthaddress:vue.birthaddress
        ,birthdate:vue.tgllahir.val
        ,identitastype:vue.identityOptionValue
        ,nomoridentitas:vue.nomoridentitas
        ,pekerjaan:vue.pekerjaanOptionValue
        ,registerdate: vue.getdatenow()
        };
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(parameters)
        };
        
        fetch(process.env.VUE_APP_WEB_API + 'api/register', requestOptions)
        .then(async response => {
            const data = await response.json();
            var message = vue.$t(data.message);
            if (data.success) {
                window.location.href = '/';
            } else {
                vue.errorMessage = vue.$t(message);
                vue.ajaxProcess = false;
            }
        })
        .catch(error => {
            vue.ajaxProcess = false;
            alert('Refresh this page' + error);
        });
    }
  },
  mounted() {
    var vue = this;
    vue.ajaxProcess = true;
    
    const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            };
    
    fetch(process.env.VUE_APP_WEB_API + 'api/register-options', requestOptions)
    .then(async response => {
        const data = await response.json();
        vue.identityoptions = data.identitas_options;
        if (vue.identityoptions.length > 0) {
            vue.identityOptionValue = vue.identityoptions[0].id;
        }
        
        vue.pekerjaanoptions = data.job_options;
        if (vue.pekerjaanoptions.length > 0) {
            vue.pekerjaanOptionValue = vue.pekerjaanoptions[0].id
        }
        vue.ajaxProcess = false;
    })
    .catch(error => {
        vue.ajaxProcess = false;
        alert('Refresh this page' + error);
    });
  },
  watch: {
    errorMessage(val) {
        if (val.length > 0) {
            setTimeout(() => { this.errorMessage = '';  }, 7000);
        }
      },
    'tgllahir.val' (val) {
        this.tgllahir.text = this.$parent.formatDate(val);
      },
  }
}
</script>
